import React from "react";

import "./PaginationFooter.scss";

class PaginationFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page:1
    };
  }

  pageUpdate(pageNo){
    const totalPages = Math.ceil((this.props.totalResults)/(this.props.limit));

    if(pageNo==='<' && this.state.page !== 1){
        this.setState({page:this.state.page - 1})
        this.props.changePage('-')
      }
   if(pageNo==='>' && this.state.page !== totalPages ){
      console.log(totalPages)
      this.setState({page:this.state.page+1})
      this.props.changePage('+')
   }


  }
  // {(this.props.page!== 1) ? ()=>this.props.changePage(-1,(-this.props.limit)): ''}
  // { ()=> this.props.changePage(+1,(this.props.limit))}
  // {(this.state.page!==1)?()=>this.pageUpdate(-1):''}

  render() {
    return (
      <div className="pagination-footer">
        <p onClick={()=>this.pageUpdate('<')}>{"<"}</p>
        <p>{this.state.page}</p>
        <p onClick= {()=>this.pageUpdate('>')}>{">"}</p>
      </div>
    );
  }
}

export default PaginationFooter;
