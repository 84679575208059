import React, { Component } from "react";
import { getYellowedText } from "../../helpers";
import "./SeeMore.scss";

class SeeMore extends Component {
  constructor(props) {
    super(props);
    this.state = { isExpended: false };
  }


  render() {
    const readMore = `Read More >>`;
    const readLess = `<< Read Less`;
    const { text, queryString } = this.props;
    const per_field_data = this.props;
    //console.log(fieldname);
    console.log(this.props);

    const { isExpended } = this.state;
    if (`${text}`.length < 500) {
    return (
        <span>
        {":"}
        <span
            className="card-left-value"
            dangerouslySetInnerHTML={{
            __html: `<span>${getYellowedText(text, queryString)}</span>`,
            }}></span>
        </span>
    );
    }
    if (`${text}`.length >= 500 && isExpended) {
    return (
        <span>
        {" "}
        <span
            className="card-left-value"
            dangerouslySetInnerHTML={{
            __html: `<span>${getYellowedText(text, queryString)}</span>`,
            }}></span>
        <button
            className="see-more-btn"
            onClick={() => this.setState({ isExpended: false })}>
            {`${readLess}`}
        </button>
        </span>
    );
    }
    if (`${text}`.length >= 500 && !isExpended) {
    return (
        <span>
        {" "}
        <span
            className="card-left-value"
            dangerouslySetInnerHTML={{
            __html: `<span>${getYellowedText(text, queryString).substring(
                0,
                200
            )}</span>`,
            }}></span>
        <button
            className="see-more-btn"
            onClick={() => this.setState({ isExpended: true })}>
            {`${readMore}`}
        </button>
            {" "}
        </span>
    );
    }

  }
}

export default SeeMore;
