import React from "react";

import SearchFilters from "./SearchFilters";
import PaginationHeader from "./PaginationHeader";
import PaginationFooter from "./PaginationFooter";
import SearchResults from "./SearchResults";

import "./AppBody.scss";

class AppBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="app-body">
        <div className="search-filters">
          <SearchFilters
            aggregations={this.props.aggregations}
            selectedFacets={this.props.selectedFacets}
            onClickBox={this.props.onClickBox}
          />
        </div>
        <div className="search-results">
          <SearchResults
            hits={this.props.hits}
            limit={this.props.limit}
            queryString={this.props.queryString}
          />
        </div>

    </div>

    );
  }
}

export default AppBody;
