import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

export default function MUIDropdown(props) {


  return (
    <div>
      <Select
        labelId={props.labelId}
        id={props.id}
        value={props.value}
        onChange={props.handleChange}
        label={props.label}>
        {props.options.map((option, index) => (
        <MenuItem key={index} value={option.value}>
            {option.title}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
