import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import Drawer from "@material-ui/core/Drawer";

import MUIButton from "../common/Button";

import "./SearchFilters.scss";

const FacetTitle = (props) => {
  return (
    <p
      style={{
        fontSize: "12px",
        color: "#8b9bad",
        textTransform: "uppercase",
        letterSpacing: "1px",
      }}>
      {props.title}
    </p>
  );
};
class SearchFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      facets: [],
      isDrawerOpen: false,
    };
  }

  render() {
    const { aggregations, selectedFacets } = this.props;
    return (
      <div>
        <div className="show-filters-button">
          <MUIButton
            title="Show Filters"
            onClick={() => this.setState({ isDrawerOpen: true })}
          />
          <Drawer
            anchor={"left"}
            open={this.state.isDrawerOpen}
            onClose={() => this.setState({ isDrawerOpen: false })}>
            <div className="aggreagtions-obj-key">
              <div>
                {Object.keys(aggregations).map((face, index) => {
                  return (
                    <div key={index}>
                      <FacetTitle title={face} />
                      {(aggregations[face].buckets || []).map(
                        (option, index1) => {
                          return (
                            <div key={index1}>
                              <div className="filter-div">
                                <div className="filter-key" key={index1}>
                                  <Checkbox
                                    defaultChecked={false}
                                    checked={
                                      selectedFacets[face]
                                        ? selectedFacets[face].includes(
                                            option.key
                                          )
                                        : false
                                    }
                                    color="primary"
                                    inputProps={{
                                      "aria-label": "secondary checkbox",
                                    }}
                                    onChange={(e) =>
                                      this.props.onClickBox(
                                        face,
                                        option.key,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <span
                                    style={{
                                      color: "#4f4f4f",
                                      fontSize: "13px",
                                    }}>
                                    {option.key.substr(0, 20)}
                                  </span>
                                </div>
                                <span
                                  style={{
                                    color: "#4f4f4f",
                                    fontSize: "13px",
                                  }}>
                                  {option.doc_count}
                                </span>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </Drawer>
        </div>
        <div className="filters-div">
          {Object.keys(aggregations).map((face, ii) => {
            return (
              <div key={ii}>
                <FacetTitle title={face} />
                {(aggregations[face].buckets || []).map((option, i) => {
                  return (
                    <div key={i} className="filter-key">
                      <div className="filter-row" key={i}>
                        <Checkbox
                          defaultChecked={false}
                          checked={
                            selectedFacets[face]
                              ? selectedFacets[face].includes(option.key)
                              : false
                          }
                          color="primary"
                          onChange={(e) =>
                            this.props.onClickBox(
                              face,
                              option.key,
                              e.target.checked
                            )
                          }
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                        <div className="key">
                          <span style={{ color: "#4f4f4f", fontSize: "13px" }}>
                            {option.key.substring(0, 20)}
                          </span>
                        </div>
                      </div>
                      <div className="value">
                        <span style={{ color: "#4f4f4f", fontSize: "13px" }}>
                          {option.doc_count}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default SearchFilters;
