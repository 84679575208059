import axios from "axios";

const BASE_URL = process.env.BASE_URL || "https://www.overcome101.com";
//const BASE_URL = process.env.BASE_URL || "http://192.168.50.144:8080";
/**
 *
 * @param {String} method
 * @param {*} url
 * @param {*} body
 * @returns
 */
function sendRequest(method, url, body) {
  return axios({
    method,
    url: `${BASE_URL}/${url}`,
    withCredentials: false,
    data: body,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
}



/**
 * Return highlighted strings
 * @param {String} textString
 * @param { Array.<String> } queryStrArray
 * @returns { String }
 */
export const getYellowedText = (textString, queryStrArray) => {
  const result = queryStrArray.reduce((acc, qS) => {
    return acc.replaceAll(
      new RegExp(qS, "ig"),
      `<strong style="background: yellow">${qS}</strong>`
    );
  }, textString);
  return result;
};

export default sendRequest;
