import React from "react";

import MUIDropdown from "../common/Dropdown";
import "./PaginationHeader.scss";

class PaginationHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  
  render() {
    const { totalResults } = this.props;
    return (
      <div className="pagination-header">
        <div className="paging-info">
          <strong>1 - 10</strong> out of <strong>{totalResults}</strong>
        </div>
        <MUIDropdown
        handleChange={this.props.handleChange}
          options={[
            {
              title: "10",
              value: 10,
            },
            {
              title: "20",
              value: 20
            },
            {
              title: "40",
              value: 40,
            },
            {
              title: "60",
              value: 60,
            },
          ]}
          label="Show"
          value={this.props.limit}
          
        />
      </div>
    );
  }
}

export default PaginationHeader;
