import React from "react";
import TextField from "@material-ui/core/TextField";

export default function MUITextField(props) {
  return (
    <div>
      <TextField
        id={props.id}
        value={props.value}
        name={props.name}
        label={props.label}
        type={props.type || "search"}
        variant="outlined"
        onChange={props.onChange}
      />
    </div>
  );
}
  