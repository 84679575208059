import React from "react";
import Button from "@material-ui/core/Button";

export default function MUIButton(props) {
  return (
    <div>
      <Button
        variant="contained"
        color={props.color || "default"}
        size="large"
        onClick={props.onClick}
      >
        {props.title}
      </Button>
    </div>
  );
}
