import React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import SeeMore from "./SeeMore";
// import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import "./SearchResults.scss";

const styles = (theme) => ({
  root: {},
  title: {
    color: "blue",
  },
});

function getIndicesOf(searchStr, str, caseSensitive) {
    var searchStrLen = searchStr.length;
    if (searchStrLen == 0) {
        return [];
    }
    var startIndex = 0, index, indices = [];
    if (!caseSensitive) {
        str = str.toLowerCase();
        searchStr = searchStr.toLowerCase();
    }
    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
        indices.push(index);
        startIndex = index + searchStrLen;
    }
    return indices;
}

class SearchResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpended: false,
      isLarge: false,
    };

  }


  render() {
    const { classes,hits } = this.props;
    return (
      <div className="results-main-div">
        {hits.map((card, index) => {
          return (
            <div key={index} style={{ marginTop: "20px" }}>
              <Card className={classes.root} variant="outlined">
                <CardContent>
                    {console.log(card.hits)}
                    {console.log(card.metadata)}
                    {console.log(card.prosecution)}

                    <div><span>examiner: {card.metadata.examiner}</span>&nbsp;•&nbsp;<span>artunit: {card.metadata.artunit}</span></div>
                    <div><span>App No: {card.hits[0].app_no}</span>
                        {(() => {
                            if (card.metadata.publicationnumber !=null) {
                                return (
                                    <span>&nbsp;•&nbsp;Pub No: {card.metadata.publicationnumber}</span>
                                )
                            }
                            return null;
                        })()}
                        {(() => {
                            if (card.metadata.patentnumber !=null) {
                                return (
                                    <span>&nbsp;•&nbsp;Patent: {card.metadata.patentnumber}</span>
                                )
                            }
                            return null;
                        })()}
                   </div>
                    <div><span>Filed: {card.metadata.filingdate}</span>
                        {(() => {
                            if (card.metadata.publicationdate !=null) {
                                return (
                                    <span>&nbsp;•&nbsp;Published: {card.metadata.publicationdate}</span>
                                )
                            }
                            return null;
                        })()}
                        {(() => {
                            if (card.metadata.grantdate !=null) {
                                return (
                                    <span>&nbsp;•&nbsp;Granted: {card.metadata.grantdate}</span>
                                )
                            }
                            return null;
                        })()}
                    </div>
                    <div><span>{card.hits[0].title}</span></div>
                    <div><span><ul class="horizontal_list">Inventor:{card.metadata.inventors.map(function(item, i){
                        return <li>&nbsp;{item}</li>
                        })}
                        </ul></span>
                    </div>
                    <div>
                        {(() => {
                            if (card.metadata.applicants !=null) {
                                return (
                                    <span><ul class="horizontal_list">Assignee:{card.metadata.applicants.map(function(item, i){
                                        return <li>&nbsp;{item}</li>
                                        })}
                                        </ul></span>
                                )
                            }
                            return null;
                        })()}
                    </div>
                    <div>
                        {(() => {
                            if (card.prosecution !=null) {
                                return (
                                    <span><ul class="vertical_list">Prosecution:{card.prosecution.map(function(item, i){
                                        if (item.bodytext !=null) {
                                            return (
                                                    <li>{item.eventdate}&nbsp;{item.eventcode}&nbsp;<a rel="noopener noreferrer" target='_target' href={"/viewpdf?id=" + item.pdf_filepath}>PDF</a>
                                                    {item.bodytext.map(function(itembodytext, i){
                                                        let temp="W";
                                                        let j=0;
                                                        for (j=0;j<4;j++) {
                                                            temp=temp+"e";
                                                        }

                                                        let indices_mark_start = getIndicesOf("<mark>", itembodytext, false);
                                                        let indices_mark_end = getIndicesOf("</mark>", itembodytext, false);
                                                        let array_start_string = []
                                                        let array_middle_string = []
                                                        let last_part_string = ""

                                                        for (j=0; j<indices_mark_start.length; j++)
                                                        {
                                                            if (j==0) {
                                                                //from 0 to <mark>
                                                                array_start_string.push(itembodytext.substring(0, indices_mark_start[j]));
                                                                //between <mark> and </mark>
                                                                array_middle_string.push(itembodytext.substring(indices_mark_start[j]+6,indices_mark_end[j]));
                                                            } else {
                                                                //from </mark> +7 to <mark>
                                                                array_start_string.push(itembodytext.substring(indices_mark_end[j-1]+7, indices_mark_start[j]));
                                                                array_middle_string.push(itembodytext.substring(indices_mark_start[j]+6,indices_mark_end[j]));
                                                            }
                                                        }
                                                        console.log(indices_mark_start, indices_mark_end);
                                                        console.log(array_start_string);
                                                        console.log(array_middle_string);
                                                        last_part_string = itembodytext.substring(indices_mark_end[indices_mark_start.length-1]+7)
                                                        return <div><span>{
                                                            array_start_string.map((value, index) => {
                                                                console.log(value);
                                                                let middle_string = array_middle_string[index];
                                                                return <span><span>{value}</span><span><mark>{array_middle_string[index]}</mark></span></span>;
                                                            })}
                                                            {last_part_string}
                                                        </span></div>
                                                    })}


                                                    </li>
                                                )
                                        }
                                        else {
                                            if (item.pdf_filepath != null) {
                                                return <li>{item.eventdate}&nbsp;{item.eventcode}&nbsp;<a rel="noopener noreferrer" target='_target' href={"/viewpdf?id=" + item.pdf_filepath}>PDF</a></li>;
                                            }
                                            else {
                                                return <li>{item.eventdate}&nbsp;{item.eventcode}</li>;
                                            }
                                        }
                                    })}
                                    </ul></span>
                                )
                            }
                            return null;
                        })()}
                    </div>

                </CardContent>
              </Card>
            </div>
          );
        })}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(SearchResults);
