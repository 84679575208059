import React from "react";
import { useEffect, useState } from "react";

import AppHeader from "./components/header/header";
import AppBody from "./components/body/AppBody";
import sendRequest from "./helpers/index";
import { debounce } from "debounce";
import Charttest from './components/common/Charttest.js';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



import "./App.css";
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      q1: "",
      q2: "",
      q3: "",
      ExaminerValue: "",
      suggestionQ1: [],
      suggestionQ2: [],
      suggestionQ3: [],
      facets: {},
      hits: [],
      aggregations: {},
      totalResults: 0,
      limit:10,
      skip:0,
      totalPages:0,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevState.facets) !== JSON.stringify(this.state.facets) || prevState.limit !== this.state.limit || prevState.skip !== this.state.skip
    ) {
      this.fetchResults();
      return;
    }
  }

  changePage = (direction) =>{

    if(direction=== '+' )
    {
        this.setState({skip:this.state.skip + this.state.limit})
    }

   if(direction === '-' )
    {

        this.setState({skip:this.state.skip - this.state.limit})

    }


  }
  fetchSuggestions = (e) => {
    return new Promise((res, reject) => {
      return res([
        { title: "Title 1", value: "Value 1" },
        { title: "Title 2", value: "Value 2" },
      ]);
    }).then((res) => {
      if (e.target.name === "q1") {
        this.setState({ suggestionQ1: res });

        //console.log(res);
        return;
      }
      if (e.target.name === "q2") {
        this.setState({ suggestionQ2: res });
        return;
      }
      if (e.target.name === "q3") {
        this.setState({ suggestionQ1: res });
        return;
      }
    });
  };

  onChangeTextField = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    debounce(this.fetchSuggestions(e), 300);
  };

  onClickBox = (face, key, isChecked) => {
    const prevState = this.state.facets[face];
    if (!prevState && isChecked === false) {
      console.log("no previous facets");
      return;
    }
    if (!prevState && isChecked === true) {
      this.setState({ facets: { ...this.state.facets, [face]: [key] } });
      return;
    }
    if (prevState && isChecked === true) {
      const newState = [...prevState];
      newState.push(key);
      this.setState({ facets: { ...this.state.facets, [face]: newState } });
      return;
    }
    if (prevState && isChecked === false) {
      const newState = [...prevState].filter((item) => item !== key);
      this.setState({ facets: { ...this.state.facets, [face]: newState } });
      return;
    }
  };

  onExaminerChange=(e) =>{
    //this.state.ExaminerValue= e.target.value ;
    console.log(e)
    this.setState({"ExaminerValue":e})
  }

  fetchResults = () => {
    this.state.testtest= "123";
    console.log(this.state);
    console.log(this.prop);
    const { q1, q2, q3, ExaminerValue } = this.state;
    if (!q1 && !q2 && !q3) {
      console.log("All query String empty, don't send request");
      return false;
    }
    document.getElementById("loader-wrapper").style.display="block";
    document.getElementById("id_search_tutorial").style.display="none";
    console.log("Fetching data");
    sendRequest("post", "post_query", {
      // ...this.state.facets,
      q1: [q1],
      q2: !q2 ? [] : [q2],
      q3: !q3 ? [] : [q3],
      ExaminerValue: ExaminerValue,
      f1: this.state.facets.artunit_filter
        ? [...this.state.facets.artunit_filter]
        : [],
      f2: this.state.facets.examiner_filter
        ? [...this.state.facets.examiner_filter]
        : [],
      f3: this.state.facets.owner_filter
        ? [...this.state.facets.owner_filter]
        : [],
      f4: [],
      limit:this.state.limit,
      skip:this.state.skip
    }).then((res) => {
      //console.log(res.data);
      const response = res.data;
      //console.log(response.hits);
      this.setState({
        hits: response.hits,
        aggregations: response.aggregations,

      });
      document.getElementById("loader-wrapper").style.display="none";
    });

  };

  render() {
    //const fetchPrices = async () => {
    //  const res = 'await fetch("http://192.168.50.144:8080/get_json");'
    const Example = () => {
        const [startDate, setStartDate] = useState(new Date());
            return (
                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
            );
    };
    const { q1, q2, q3, hits, aggregations, totalResults, facets} = this.state;

    return (
      <div>
        <div className="main-layout">
            <div id="container" style={{ whiteSpace: "nowrap" }}>
                <div id="image" style={{ display: "inline" }}>
                    <img width="40px" src="https://www.overcome101.com/images/apple-touch-icon-iphone-72.png"/>
                </div>
                <div id="texts" style={{ display: "inline", whiteSpace: "nowrap" }}>
                    <span class="header-text-overcome">Overcome 101</span>
                    <span class="header-text-beta">beta</span>
                    <span class="header-text-links">Why?</span>
                    <span class="header-text-links">How to search?</span>
                    <span class="header-text-links">Pricing</span>
                    <span class="header-text-links">FAQ</span>
                </div>

            </div>
        </div>
        <div className="main-layout">
          <div className="main-layout-header">
            <AppHeader
              fetchResults={this.fetchResults}
              q1={q1}
              q2={q2}
              q3={q3}
              onExaminerChange={this.onExaminerChange}
              suggestionQ1={this.state.suggestionQ1}
              suggestionQ2={this.state.suggestionQ2}
              suggestionQ3={this.state.suggestionQ3}
              onChangeTextField={this.onChangeTextField}
            />
          </div>
          <div className="main-layout-body">
            <AppBody
              hits={hits}
              aggregations={aggregations}
              totalResults={totalResults}
              selectedFacets={facets}
              onClickBox={this.onClickBox}
              queryString={[q1, q2, q3].filter((item) => item)}
              limit={this.state.limit}
              page={this.state.page}
              skip={this.state.skip}
              handleChange={(e)=>this.setState({limit: e.target.value})}
              changePage={this.changePage}
            />
          </div>
        </div>
        <div id="id_search_tutorial" className="search_tutorial">
            <p>Database last updated: 10 March 2022</p>
            <p>Why?</p>
            <p>Overcome 101 is developed by the same team who developed Aspator and Petapator one decade ago.  Now, the focus is on analyzing USPTO patent office actions.  We are developing Overcome 101 because we found the patent examination sometimes are convoluted, especially when dealing U.S.C. 101 requirements.</p>
            <ul>
            <li><p>More than 500,000 recent office actions and applicant arguments are at your fingertips for your study and analysis.</p></li>
            <li><p>Search by examiner, art unit, publication number</p></li>
            <li><p>Learn how others overcome U.S.C. 101, 102, 103 and more</p></li>
            </ul>

        </div>
        <div id="loader-wrapper">
            <div id="loader"></div>
        </div>
        <div className="main-layout-footer">
            <div id="container" style={{ whiteSpace: "nowrap" }}>
                <div id="image" style={{ display: "inline" }}>
                <span class="header-text-links">© 2022 Petapator</span>
                <span class="header-text-links">Privacy Policy</span>
                <span class="header-text-links">Terms</span>
                </div>
            </div>
        </div>
      </div>
    );
  }
}

export default App;
